import { createContext, useContext, useState, type ReactNode } from "react";

import type { useLoaderData } from "@remix-run/react";
import type { loader } from "~/routes/receiver.$shop.gifts.$id/route";
import type { Gift, Shop } from "~/types";

type GiftData = ReturnType<typeof useLoaderData<typeof loader>> & {
  id: Gift["id"];
  shop: Shop;
};

interface GiftContextType {
  giftData: GiftData | null;
  setGiftData: (data: GiftData) => void;
}

const GiftContext = createContext<GiftContextType | undefined>(undefined);

export const GiftProvider = ({ children }: { children: ReactNode }) => {
  const [giftData, setGiftData] = useState<GiftData | null>(null);

  return (
    <GiftContext.Provider value={{ giftData, setGiftData }}>
      {children}
    </GiftContext.Provider>
  );
};

export const useGiftContext = (): GiftContextType => {
  const context = useContext(GiftContext);
  if (context === undefined) {
    throw new Error("usecontext must be used within a GiftProvider");
  }
  return context;
};
